ion-alert {
  .alert-button {
    &.alert-regular-button {
      color: var(--ion-color-dark-tint);
    }

    &.alert-danger-button {
      color: var(--ion-color-danger-tint);
    }
  }
}
